<script>
/***
 Name: upload
 Code: sa0ChunLuyu
 Time: 2021/10/18 09:49
 Remark: 文件上传工具
 */
export default {
  data() {
    return {
      file: false,
      filename: '',
      file_url: '',
    }
  },
  mounted() {
  },
  methods: {
    uploadClick() {
      let param = new FormData()
      param.append('file', this.file)
      param.append('filename', this.filename)

      this.$sa0.file({
        url: this.$api('AssetsUpload'),
        data: param
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          error: () => {
            layer.msg('上传失败')
          },
          then: (res) => {
            layer.msg('上传成功')
            this.file_url = res.data.url
          }
        })
      })
    },
    uploadChange(e) {
      this.file = e.target.files[0];
      this.filename = e.target.files[0].name
    }
  }
}
</script>
<template>
  <div>
    <input type="file" @change="uploadChange">
    <a-input v-model="filename"></a-input>
    <a-button @click="uploadClick()">确定上传</a-button>
    <div>
      <a-input v-model="file_url"></a-input>
    </div>
  </div>
</template>
<style scoped>

</style>
